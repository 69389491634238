$halalColor: #4ca85a;
$textColor: #959595;
$lineColor: #e0e0e0;

.desktop-header {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 120px;
  border-bottom: 1px solid $lineColor;
  padding-bottom: 24px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }

  .logo {
    display: flex;
    align-items: flex-end;

    img {
      width: 65px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: $halalColor;
      margin-left: 16px;
    }
  }

  .lang {
    font-size: 13px;

    a {
      color: #8e8e8e;

      &:hover {
        text-decoration: none;
        color: #444;
      }

      &.active {
        color: #444;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;

    .menu-container {
      display: flex;
      align-items: center;
    }
  }

  .menu {
    position: relative;
    margin: 0 12px;

    &:hover {
      .dropdown {
        display: block;
      }
    }

    @media (max-width: 991px) {
      margin: 0;
    }

    &.certificate-btn {
      margin-right: 0;

      @media (max-width: 991px) {
        display: none;
      }
    }

    a {
      color: #444444;
      font-size: 16px;

      &:hover {
        text-decoration: none;
      }
    }

    .round-btn {
      display: block;
      background-color: $halalColor;
      color: white;
      font-weight: bold;
      padding: 8px 16px;
      border-radius: 30px;

      &:hover {
        background-color: darken($color: $halalColor, $amount: 10);
        text-decoration: none;
      }
    }

    .dropdown {
      display: none;
      background-color: white;
      font-size: 14px;
      position: absolute;
      // transition: all 0.5s ease;
      // margin-top: 1rem;
      border: 1px solid #e0e0e0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      left: 0;
      z-index: 10;

      li {
        position: relative;
        // border-bottom: 1px solid $lineColor;
        white-space: nowrap;

        a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 16px;

          &:hover {
            background-color: $halalColor;
            color: white;
          }

          // background-color: red;
        }
      }
    }
  }
}

.mobile-header {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  .top-section {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 95px;

    a {
      text-decoration: none;
    }
  }

  .logo {
    img {
      width: 39px;
    }

    .title {
      font-size: 14px;
      font-weight: bold;
      color: $halalColor;
      margin-top: 10px;
    }
  }

  .right-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: right;

    .lang {
      font-size: 13px;

      a {
        color: #8e8e8e;

        &:hover {
          text-decoration: none;
          color: #444;
        }

        &.active {
          color: #444;
        }
      }
    }

    .round-btn {
      display: block;
      background-color: $halalColor;
      color: white;
      font-size: 14px;
      font-weight: bold;
      padding: 8px 16px;
      border-radius: 30px;

      &:hover {
        background-color: darken($color: $halalColor, $amount: 10);
        text-decoration: none;
      }
    }
  }

  .main-menus {
    padding: 16px 0;
    border-top: 1px solid $lineColor;
    border-bottom: 1px solid $lineColor;
    margin-top: 24px;

    ul {
      display: flex;
      justify-content: space-between;

      a {
        color: #444;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }
}

.navlink-active {
  color: $halalColor !important;
  font-weight: bold;
  // color: red;
}

.header-menu {
  &:hover {
    color: $halalColor !important;
  }
}