@import 'reset';
// @import "slick";
// @import "slick-theme";

$halalColor: #4ca85a;
$textColor: #959595;
$lineColor: #e0e0e0;

@import 'header';

body {
  width: 100%;
  font-family: sans-serif;
  padding: 0 16px;
  letter-spacing: -0.4px;
  overflow-y: scroll;

  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}

button {
  appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

header {
  width: 100%;
  // padding: 16px 24px 0 24px;
  padding-top: 16px;
  margin-bottom: 32px;

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: $halalColor;
    }
  }
}

.index-container {
  display: flex;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .col-1 {
    flex: 0 60%;
    margin-right: 16px;

    @media (max-width: 991px) {
      flex: 1 100%;
      margin-right: 0;
    }

    .index-cta {
      // @media (max-width: 991px) {
      //   display: none;
      // }

      display: block;
      text-align: center;
      border: 1px solid $halalColor;
      background-color: white;
      color: $halalColor;
      font-size: 19px;
      font-weight: bold;
      width: 100%;
      padding: 15px 0;
      border-radius: 4px;
      margin-bottom: 32px;
      line-height: 1.2em;

      &:hover {
        background-color: $halalColor;
        color: white;
        text-decoration: none;
      }
    }

    .certificates,
    .notices {
      padding: 24px;
      border: 1px solid #ededed;
      border-radius: 4px;

      h3 {
        color: $halalColor;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }

    .notices {
      @media (max-width: 991px) {
        margin-bottom: 32px;
      }
    }

    .certificates {
      margin-bottom: 32px;
    }

    .flex-body {
      display: flex;
      flex-wrap: wrap;

      .flex-item {
        flex: 1 25%;
        //flex: 1 1 20%;
        //flex: 1 1 13%;
        text-align: center;
        padding-bottom: 10px;

        @media (max-width: 991px) {
          flex: 1 50%;
          margin-bottom: 16px;
        }

        img {
          display: inline;
          //width: 80px;
          width: 70px;
        }

        p {
          font-size: 14px;
          color: $textColor;
          padding-top: 16px;
        }
      }
    }

    .notice {
      font-size: 16px;
      margin-bottom: 11px;

      a {
        text-decoration: none;
        color: $textColor;

        &:hover {
          text-decoration: none;
          color: $halalColor;
        }
      }

      ul {
        li {
          width: 485px;
          //overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $textColor;

          .title {
            width: 485px;
            //overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $textColor;
          }

          @media (max-width: 991px) {
            max-width: 290px;
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .col-2 {
    margin-left: 16px;
    flex: 0 40%;
    border: 1px solid #ededed;
    border-radius: 4px;
    padding: 24px;

    @media (max-width: 991px) {
      flex: 0 100%;
      max-width: 100%;
      margin: 0;
    }
  }

  .col-2 > div {
    height: 100%;
  }
}

.content-body {
  // padding: 0 24px;
  text-align: left;
  margin-top: 24px;

  h3 {
    color: $halalColor;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    color: $textColor;
    // padding-top: 16px;
    line-height: 1.5em;
  }
}

.collab-companies {
  max-width: 1140px;
  margin: 32px auto 0 auto;
  padding: 24px;
  border: 1px solid #ededed;

  h3 {
    color: $halalColor;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .flex-body {
    // background-color: red;
    display: flex;
    flex-wrap: wrap;

    .flex-item {
      flex: 1 auto;
      text-align: center;

      @media (max-width: 991px) {
        flex: 1 50%;
        margin-bottom: 16px;
      }
    }

    img {
      display: inline;
      width: 80px;
    }
  }
}

a {
  text-decoration: none;
  color: #0094ff;

  &:hover {
    text-decoration: underline;
  }
}

section {
  max-width: 718px;
  margin: 0 auto 64px auto;

  &.tc {
    text-align: center;
  }

  &.guide {
    max-width: 1140px;

    .flex-guide {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      color: #959595;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }

    .step {
      max-width: 267px;

      img {
        margin-bottom: 8px;
      }

      &.first {
        margin-right: 50px;

        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 40px;
        }
      }

      &.second {
        margin-left: 50px;

        @media (max-width: 991px) {
          margin-left: 0;
        }
      }
    }

    // .usage-sample {
    //   display: inline-block;
    //   margin-top: 24px;

    //   @media (max-width: 991px) {
    //     display: none;
    //   }
    // }

    // .usage-sample-mobile {
    //   display: none;
    //   margin-top: 24px;

    //   @media (max-width: 991px) {
    //     display: inline-block;
    //   }
    // }

    p {
      margin-bottom: 24px;

      span {
        font-weight: bold;
        color: #306a39;
      }
    }

    // width: 1140px;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    color: $halalColor;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    color: $textColor;
    line-height: 30px;
  }

  .flex-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 991px) {
    }

    .logo {
      flex: 1 25%;
      text-align: center;
      margin-bottom: 24px;

      @media (max-width: 991px) {
        flex: 1 50%;
      }

      img {
        display: inline;
        margin-bottom: 8px;

        @media (max-width: 991px) {
          width: 50%;
        }
      }
    }
  }

  .chart {
    width: 100%;
  }
}

.price-table {
  width: 100%;
  color: $textColor;
  border: 1px solid #f0f0f0;

  .label {
    background-color: #f6fff8;
    border-right: 1px solid #f0f0f0;
  }

  tr {
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
  }

  td {
    padding: 16px 32px;
    line-height: 1.5em;

    @media (max-width: 991px) {
      padding: 16px;
    }

    ol {
      margin-top: 8px;
      margin-left: 19px;
      li {
        list-style-type: decimal;
        margin-bottom: 6px;
      }
    }
  }
}

.indent {
  display: block;
  padding-left: 24px;
}

.certificate-cta {
  margin: auto;
  display: block;
  background-color: $halalColor;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 16px;
  width: 324px;
  text-align: center;
  border-radius: 56px;

  &:hover {
    background-color: darken($color: $halalColor, $amount: 10);
  }
}

.map {
  padding-bottom: 24px;
  width: 100%;
}

.contact-info {
  line-height: 2em;
}

.input-container {
  margin-bottom: 24px;

  .label {
    font-size: 14px;
    color: #6a6a6a;
    margin-bottom: 9px;
  }

  .require {
    color: red;
  }

  input {
    width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    appearance: none;
    outline: none;

    &:focus {
      border: 1px solid $halalColor;
    }
  }

  textarea {
    width: 100%;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    padding: 16px;
    font-size: 16px;
    resize: none;
    appearance: none;
    outline: none;

    &:focus {
      border: 1px solid $halalColor;
    }
  }
}

.button-container {
  width: 100%;
  text-align: right;

  @media (max-width: 991px) {
    text-align: center;
  }
}

.submit-btn {
  background-color: $halalColor;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding: 16px;
  width: 324px;
  text-align: center;
  border-radius: 56px;

  &:hover {
    background-color: darken($color: $halalColor, $amount: 10);
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: #bdbdbd;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
}

::placeholder {
  color: #bdbdbd;
}

footer {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #bdbdbd;
  padding: 24px 0;
  line-height: 1.3em;
  flex-wrap: wrap;

  // border-bottom: 1px solid $lineColor;
  .logo {
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
}

section {
  footer {
    border-top: 1px solid $lineColor;
  }
}

.foodcerti-link {
  display: inline-block;
  margin-right: 8px;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }
}

.chart-carousel {
  @media (max-width: 991px) {
    width: 100%;
  }
}

.carousel-test-container {
  width: 550px;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.carousel-test {
  width: 100%;
  // border: 1px solid black;
}

.content-1 {
  padding: 24px;
  text-align: center;

  &.news {
    img {
      width: 100%;
    }

    a {
      appearance: none;
      outline: none;
      text-decoration: none;
    }
  }

  img {
    display: inline-block;
    width: 60%;
    margin-bottom: 24px;
  }

  .content-body {
    text-align: left;

    h3 {
      color: $halalColor;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    a {
      color: $halalColor;
      text-decoration: none;
    }

    p {
      font-size: 14px;
      color: $textColor;
      // padding-top: 16px;
      line-height: 1.5em;
    }
  }
}

.content-2 {
  padding: 24px;
  text-align: center;

  img {
    display: inline-block;
    width: 100%;
    margin-bottom: 32px;
  }

  .content-body {
    text-align: left;

    h3 {
      color: $halalColor;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: $textColor;
      // padding-top: 16px;
      line-height: 1.5em;
    }
  }
}

.steps-desktop {
  @media (max-width: 991px) {
    display: none;
  }
}

.steps-mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.br-mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.history {
  @media (max-width: 991px) {
    display: none;
  }
}

.history-mobile {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

// tab
.flex-tab {
  display: flex;
  align-items: center;

  .tab {
    flex: 1;

    input[type='radio'] {
      display: none;
    }

    label {
      display: block;
      text-align: center;
      border: 1px solid $lineColor;
      padding: 16px 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: #b1b1b1;
    }

    [type='radio']:checked ~ label {
      background: $halalColor;
      color: white;
    }
  }
}

.news-container {
  display: flex;
  margin: -8px -16px 0 -16px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .news-item {
    flex: 1;
    border: 1px solid $lineColor;
    padding: 18px;
    margin: 16px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      border: 1px solid $halalColor;
    }

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-bottom: 0;
    }

    .category,
    .date {
      font-size: 13px;
      color: #a9a9a9;
    }

    .title {
      color: $halalColor;
      font-size: 15px;
      font-weight: bold;
      margin: 16px 0;
      line-height: 1.5em;
    }
  }
}

.news-header {
  border-bottom: 2px solid $halalColor;
  padding-top: 20px;
  padding-bottom: 32px;

  .category,
  .date {
    font-size: 13px;
    color: #a9a9a9;
  }

  .title {
    color: $halalColor;
    font-size: 18px;
    font-weight: bold;
    margin: 16px 0;
    line-height: 1.5em;
  }
}

.news-body {
  // border-top: 1px solid $lineColor;
  padding-top: 32px;

  img {
    width: 100%;
    margin-bottom: 32px;
  }
}

.carousel {
  height: 100%;

  .slide {
    background: white !important;
    padding: 0 1px !important;
  }

  a {
    text-decoration: none;
  }

  .control-dots {
    .dot {
      box-shadow: none !important;
      background: gray !important;

      &.selected {
        background: $halalColor !important;
      }
    }
  }
}

.blockchain-image {
  width: 60% !important;
  margin-bottom: 24px;
}

.chart-title {
  text-align: left;
  color: #4ca85a;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 24px !important;
}

.carousel-chart {
  height: 330px;
}

.notice-table {
  .notice-row {
    border: 1px solid $lineColor;
    padding: 16px;
    margin-bottom: 24px;

    &:hover {
      border: 1px solid $halalColor;
    }

    a {
      text-decoration: none;
    }

    .title {
      color: $halalColor;
      font-size: 15px;
      font-weight: bold;
      margin: 16px 0;
      line-height: 1.5em;
    }

    .category,
    .date {
      font-size: 13px;
      color: #a9a9a9;
    }
  }
}

.notice-detail-container {
  width: 718px;
  margin: 0 auto;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    color: $textColor;
    line-height: 1.5em;
  }

  .notice-header {
    border-bottom: 2px solid $halalColor;
    padding-bottom: 24px;
    margin-bottom: 24px;

    .title {
      color: $halalColor;
      font-size: 18px;
      font-weight: bold;
      margin: 16px 0;
      line-height: 1.5em;
    }

    .category,
    .date {
      font-size: 13px;
      color: #a9a9a9;
    }
  }
}

.error-page {
  text-align: center;
}

.page-container {
  flex-grow: 1;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.guide-grid {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  // column-gap: 10px;
  display: flex;
  // align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  &.v2 {
    grid-template-columns: 1fr 1fr 1fr;
    // column-gap: 23px;
  }

  img {
    display: inline-block;
    margin-bottom: 31px;
  }

  h4 {
    color: $halalColor;
    font-size: 20px;
    margin-bottom: 22px;
    line-height: 1.5em;
  }

  p {
    line-height: 1.8em;
    font-size: 14px;
  }

  .item1 {
    // grid-column: 1;
    flex: 0 25%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;
    margin-right: 10px;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .item2 {
    // grid-column: 2;
    flex: 0 25%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;
    margin-right: 10px;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .item3 {
    // grid-column: 3;
    flex: 0 25%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;
    margin-right: 10px;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .item4 {
    // grid-column: 4;
    flex: 0 25%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;

    @media (max-width: 991px) {
      flex: 0 100%;
    }
  }

  .item5 {
    flex: 0 33%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;
    margin-right: 10px;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .item6 {
    flex: 0 33%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;
    margin-right: 10px;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .item7 {
    flex: 0 33%;
    border: 1px solid #ececec;
    text-align: center;
    padding: 58px 0;

    @media (max-width: 991px) {
      flex: 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.flow-image {
  // display: inline-block;
  margin: 0 auto;
  // margin-top: 24px;

  @media (max-width: 991px) {
    display: none;
  }
}

.flow-image-mobile {
  display: none;
  margin: 0 auto;
  // margin-top: 24px;

  @media (max-width: 991px) {
    display: block;
  }
}

.documents {
  list-style: decimal;
  margin-left: 18px;
  color: #959595;

  li {
    margin-bottom: 8px;
  }
}

.documents-new {
  list-style: none;
  margin-left: 0px;
  color: #959595;

  li {
    margin-bottom: 8px;
  }
}

.halal-flow {
  width: 100%;
}

.carousel .slide img {
  pointer-events: auto;
}